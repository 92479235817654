import * as wasm from './kdtree_fast_bg.wasm';

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0;
function getUint8Memory0() {
    if (cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let cachedFloat64Memory0;
function getFloat64Memory0() {
    if (cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

let WASM_VECTOR_LEN = 0;

function passArrayF64ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 8);
    getFloat64Memory0().set(arg, ptr / 8);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}
/**
*/
export class KdTree {

    static __wrap(ptr) {
        const obj = Object.create(KdTree.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_kdtree_free(ptr);
    }
    /**
    * @param {number} dimensions
    */
    constructor(dimensions) {
        const ret = wasm.kdtree_new(dimensions);
        return KdTree.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    size() {
        const ret = wasm.kdtree_size(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {Float64Array} point
    * @param {number} radius
    * @returns {any}
    */
    within(point, radius) {
        const ptr0 = passArrayF64ToWasm0(point, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.kdtree_within(this.ptr, ptr0, len0, radius);
        return takeObject(ret);
    }
    /**
    * @param {Float64Array} point
    * @param {number} num
    * @returns {any}
    */
    nearest(point, num) {
        const ptr0 = passArrayF64ToWasm0(point, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.kdtree_nearest(this.ptr, ptr0, len0, num);
        return takeObject(ret);
    }
    /**
    * @param {Float64Array} point
    * @param {number} data
    */
    add(point, data) {
        const ptr0 = passArrayF64ToWasm0(point, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.kdtree_add(this.ptr, ptr0, len0, data);
    }
    /**
    */
    drop() {
        const ptr = this.__destroy_into_raw();
        wasm.kdtree_drop(ptr);
    }
}

export function __wbindgen_json_parse(arg0, arg1) {
    const ret = JSON.parse(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export function __wbg_error_2e25acc3016c232b(arg0, arg1) {
    console.error(getStringFromWasm0(arg0, arg1));
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);

