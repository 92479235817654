<template>
  <div>
    <textarea ref="textarea" :value="code" @input="updateCode"></textarea>
  </div>
</template>

<script>
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/eclipse.css'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/edit/closebrackets.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/hint/show-hint.js' // 添加 showHint 插件
import 'codemirror/addon/hint/anyword-hint.js' // 添加 anyword-hint 插件

export default {
  name: 'code-mirror',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      codeMirror: null,
      code: this.value
    }
  },
  mounted() {
    this.codeMirror = CodeMirror.fromTextArea(this.$refs.textarea, {
      mode: 'python',
      lineNumbers: true,
      lineWrapping: true,
      autoCloseBrackets: true,
      matchBrackets: true,
      styleActiveLine: true,
      theme: 'eclipse',
      tabSize: 4,
      indentUnit: 4,
      historySize: 10,
      extraKeys: {
        Tab: (cm) => {
          const spaces = Array(cm.getOption('indentUnit') + 1).join(' ')
          cm.replaceSelection(spaces)
        }
      },
      hintOptions: {
        hintWords: ['if', 'else', 'while', 'for', 'in', 'range', 'def', 'return'] // 定义提示词汇表
      }
    })

    this.codeMirror.on('change', this.handleChange)
  },
  beforeUnmount() {
    this.codeMirror.off('change', this.handleChange)
  },
  methods: {
    handleChange() {
      this.code = this.codeMirror.getValue()
      this.$emit('input', this.code)
    },
    updateCode(newCode) {
      this.code = newCode
      this.codeMirror.setValue(newCode)
    }
  }
}
</script>

<style>
.CodeMirror-gutter {
  min-width: 29px !important;
}
.CodeMirror-sizer {
  margin-left: 30px !important;
}
.CodeMirror-linenumber {
  left: 0px !important;
  min-width: 22px !important;
}
</style>
